import { Intercom } from "@mathieustan/vue-intercom";

const appId = process.env.VUE_APP_INTERCOM_APP_ID;

const intercom = new Intercom({ appId });

const userArgs = user => {
  return {
    user_id: user.encrypted_id,
    email: user.email,
    name: user.full_name
  };
};

const intercomSetting = (user, lang) => {
  if (user) {
    return {
      ...userArgs(user),
      language_override: lang
    };
  } else {
    return {
      language_override: lang
    };
  }
};

export const startIntercomMessenger = (user, lang = "en") => {
  if (!intercom.ready) {
    intercom.once("ready", () => rebootIntercom(user, lang));
  } else {
    rebootIntercom(user, lang);
  }
};

export const rebootIntercom = (user, lang = "en") => {
  intercom.shutdown();

  if (intercom.isBooted) return;
  intercom.boot(intercomSetting(user, lang));
};

export const updateIntercomData = args => {
  intercom.update(args);
};

export const updateUserIntercom = user => {
  updateIntercomData(userArgs(user));
};
