import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    endpoint: "search-items",
    items: [],
    total: 0
  }),
  getters: {
    items(state) {
      return state.items;
    },
    hasItems(state) {
      return state.items.length > 0;
    },
    total(state) {
      return state.total;
    }
  },
  actions: {
    fetchSearch({ commit, state }, params) {
      return axios
        .get(`ats/${state.endpoint}`, {
          params
        })
        .then(({ data }) => {
          commit(
            "setItems",
            params.page === 1 ? data.data : [...state.items, ...data.data]
          );
          commit("setTotal", data.total);
        });
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setTotal(state, total) {
      state.total = total;
    }
  }
};
