import axios from "axios";

export const translateObjectKeys = (language, keys, fallback) => {
  fallback = fallback || "en";
  return object => {
    const translations = keys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: object[key][language] || object[key][fallback]
      }),
      {}
    );
    return {
      ...object,
      ...translations
    };
  };
};

export const translateText = async (source, target, text) => {
  const {
    data: { text: translatedText }
  } = await axios.post("translate", {
    source,
    target,
    text
  });
  return translatedText;
};
