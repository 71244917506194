import { baseEncode64 } from "@/utils/string";

export const createAjaxQuery = () => ({
  filters: [],
  sort: {},
  orderBy(column, order) {
    this.sort = {
      column,
      order
    };
    return this;
  },
  where(column, value, operator, boolean) {
    this.filters.push([column, operator || "=", value, boolean || "and"]);
    return this;
  },
  orWhere(column, value, operator) {
    return this.where(column, value, operator, "or");
  },
  orLike(column, value) {
    return this.where(column, value, "like", "or");
  },
  like(column, value, boolean) {
    return this.where(column, value, "like", boolean || "and");
  },
  reset() {
    this.filters = [];
  },
  toString(encode) {
    if (encode) {
      return encodeURIComponent(
        baseEncode64(JSON.stringify({ where: this.filters, sort: this.sort }))
      );
    } else {
      return baseEncode64(
        JSON.stringify({ where: this.filters, sort: this.sort })
      );
    }
  }
});
