import { createAjaxQuery } from "@/utils/ajaxQuery";
import axios from "axios";
import { filtersToRequest, toString } from "@/utils/filters";

const getAjaxQuery = state => {
  const query = createAjaxQuery();
  if (state.search && Array.isArray(state.searchables)) {
    state.searchables.forEach(column =>
      query.orLike(column, `%${state.search}%`)
    );
  }
  query.orderBy(state.sort.field, state.sort.order);
  return query;
};

export const debounce = (f, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => f(...args), wait);
  };
};

const debouncedSearch = debounce(({ commit, dispatch }, search) => {
  commit("setSearch", search);
  commit("setCurrentPage", 1);
  dispatch("paginate");
}, 500);

export default {
  applySearch(context, search) {
    return debouncedSearch(context, search);
  },
  paginate({ commit, state, getters }) {
    commit("setIsLoaded", false);
    const query = getAjaxQuery(state);
    return axios
      .get(`/ats/${getters.getEndpoint}`, {
        params: {
          ...getters.getExtraEndpointArguments,
          j: query.toString(false),
          f: toString(filtersToRequest(getters.filters)),
          per_page: state.perPage,
          page: state.currentPage
        }
      })
      .then(({ data }) => {
        commit("setItems", data.data);
        commit("setMetadata", data.metadata);
        commit("setTotal", data.total);
        commit("setIsLoaded", true);
      });
  },
  fetchMultiselect({ commit, state, getters }, fetchParams) {
    return axios
      .get(`/ats/${getters.getMultiselectEndpoint}`, {
        params: fetchParams
      })
      .then(({ data }) => {
        commit(
          "setMultiselectItems",
          fetchParams.page === 1
            ? data.data
            : [...state.multiselectItems, ...data.data]
        );
        commit("setMultiselectTotal", data.total);
        return data;
      });
  },
  fetchAll({ commit, state, getters }) {
    commit("setIsLoaded", false);
    const query = createAjaxQuery();
    if (state.search && Array.isArray(state.searchables)) {
      state.searchables.forEach(column =>
        query.orLike(column, `%${state.search}%`)
      );
    }
    query.orderBy(state.sort.field, state.sort.order);
    return axios
      .get(`/ats/${getters.getEndpoint}?j=${query.toString(true)}`)
      .then(({ data }) => commit("setItems", data));
  },
  fetchOne({ commit, getters }, id) {
    return axios
      .get(`/ats/${getters.getEndpoint}/${id}`)
      .then(({ data }) => commit("setItem", data));
  },
  fetchOptions({ commit, getters }) {
    return axios
      .get(`/ats/options/${getters.getEndpoint}`)
      .then(({ data }) => commit("setOptions", data));
  },
  fetchSchema({ commit, getters }) {
    return axios
      .get(`/ats/${getters.schemaEndpoint}`)
      .then(({ data }) => commit("setSchema", data));
  },
  create({ getters }, payload) {
    return axios.post(`ats/${getters.getEndpoint}`, payload);
  },
  delete({ commit, getters }, id) {
    return axios
      .delete(`ats/${getters.getEndpoint}/${id}`)
      .then(() => commit("delete", id));
  },
  update({ getters }, { id, ...payload }) {
    return axios.put(`ats/${getters.getEndpoint}/${id}`, payload);
  },
  updateFeed({ getters }, { id, ...payload }) {
    return axios.put(`ats/${getters.getEndpoint}/${id}/feed`, payload);
  },
  loadDefaultSavedFilters({ commit, getters }) {
    return axios
      .get(`ats/default-saved-filters/${getters.getDataSourceName}`)
      .then(({ data }) => {
        commit("loadFilters", data);
      });
  },
  updateAndSetItem({ commit, getters }, { id, ...payload }) {
    return axios
      .put(`ats/${getters.getEndpoint}/${id}`, payload)
      .then(({ data }) => commit("setItem", data));
  },
  applyBTableSort({ commit, dispatch }, sort) {
    commit("setBTableSort", sort);
    dispatch("paginate");
  },
  applySort({ commit, dispatch }, field) {
    commit("setSort", field);
    dispatch("paginate");
  },
  reset({ commit }) {
    commit("reset");
  }
};
