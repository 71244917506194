export default () => ({
  items: [],
  item: {},
  multiselectItems: [],
  multiselectTotal: 0,
  options: [],
  search: "",
  extraEndpointArguments: [],
  sort: { field: "created_at", order: "desc" },
  sortKeyMapping: {},
  isLoaded: false,
  currentPage: 1,
  perPage: 10,
  total: 0,
  filters: [],
  schema: [],
  dataSourceName: null,
  schemaEndpoint: null,
  metadata: {
    product: {
      name: "",
      usage: 0,
      limit: 0
    }
  }
});
