import axios from "axios";
import { debounce } from "@/store/ajax/actions";

const debouncedSearch = debounce(({ commit, dispatch }, search) => {
  commit("setSearch", search);
  commit("setCurrentPage", 1);
  dispatch("fetchNocJobTitles");
}, 500);

export const parseNocJobTitle = (nocJobTitle, lang) => {
  return {
    ...nocJobTitle,
    title: nocJobTitle[`title_${lang}`]
  };
};

export default {
  namespaced: true,
  state: () => ({
    search: "",
    items: [],
    isLoaded: false,
    currentPage: 1,
    perPage: 10,
    total: 0
  }),
  getters: {
    items(state, getters, rootState, rootGetters) {
      const lang = rootGetters["me/lg"];
      return state.items.map(item => parseNocJobTitle(item, lang));
    },
    hasNextPage(state) {
      return state.perPage * state.currentPage < state.total;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
    currentPage(state) {
      return state.currentPage;
    }
  },
  actions: {
    applySearch(context, search) {
      return debouncedSearch(context, search);
    },
    fetchNocJobTitles({ commit, state, getters, rootGetters }) {
      commit("setIsLoaded", false);
      const lang = rootGetters["me/lg"];
      return axios
        .get(
          `/noc-job-titles?title=${state.search}&page=${state.currentPage}&lang=${lang}`
        )
        .then(({ data }) => {
          commit(
            "setItems",
            state.currentPage === 1
              ? data.data
              : getters.items.concat(data.data)
          );
          commit("setTotal", data.total);
          commit("setIsLoaded", true);
        });
    },
    loadMore({ commit, getters, dispatch }) {
      if (getters.hasNextPage) {
        const nextPage = getters.currentPage + 1;
        commit("setCurrentPage", nextPage);
        dispatch("fetchNocJobTitles");
      }
    }
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setTotal(state, value) {
      state.total = value;
    }
  }
};
