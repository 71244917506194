import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    endpoint: "companies",
    searchables: ["name_fr", "phone_number", "location->city"]
  }),
  getters: {
    ...getters,
    sort(state) {
      return state.sort;
    }
  },
  actions: {
    ...actions,
    updateCompanyImage({ getters }, { id, payload }) {
      return axios.post(`ats/${getters.getEndpoint}/${id}/image`, payload);
    }
  },
  mutations: {
    ...mutations
  }
};
