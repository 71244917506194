import axios from "axios";
import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";
import { jobboards } from "@/enums/jobboards";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    endpoint: "applications",
    searchables: [
      "candidates.full_name",
      "candidates.email",
      "offer_content.title"
    ],
    dataSourceName: "application",
    schemaEndpoint: "schemas/application"
  }),
  getters: {
    ...getters,
    items(state) {
      return state.items.map(item => {
        return {
          ...item,
          source: jobboards.from(item.source).label ?? item.source
        };
      });
    }
  },
  actions: {
    ...actions,
    addDocument(context, { id, form }) {
      return axios.post(`ats/applications/${id}/documents`, form);
    },
    deleteDocument(context, { applicationId, documentId }) {
      return axios.delete(
        `ats/applications/${applicationId}/documents/${documentId}`
      );
    },
    updateApplicationStatus(context, { id, status }) {
      return axios.put(`ats/applications/${id}`, { status });
    }
  },
  mutations
};
