const state = () => ({
  isFetching: false,
  activeLoaders: {}
});

const getters = {
  isFetching(state) {
    return state.isFetching;
  },
  showLoader(state) {
    return name => state.activeLoaders[name] || false;
  }
};

const actions = {
  stopFetching({ commit }) {
    return commit("stopFetching");
  },
  startFetching({ commit }) {
    return commit("startFetching");
  }
};
const mutations = {
  startFetching(state) {
    state.isFetching = true;
    state.activeLoaders = {
      ...state.activeLoaders,
      main: true
    };
  },
  stopFetching(state) {
    state.isFetching = false;
    state.activeLoaders = {
      ...state.activeLoaders,
      main: false
    };
  },
  startLoader(state, name) {
    state.activeLoaders = {
      ...state.activeLoaders,
      [name]: true
    };
  },
  stopLoader(state, name) {
    state.activeLoaders = {
      ...state.activeLoaders,
      [name]: false
    };
  }
};

export default { namespaced: true, state, getters, actions, mutations };
