import axios from "axios";

const state = () => ({
  items: []
});

const getters = {
  items(state) {
    return state.items;
  }
};

const actions = {
  fetch({ commit }) {
    return axios
      .get("/ats/recent-activities")
      .then(({ data }) => commit("setItems", data));
  }
};
const mutations = {
  setItems(state, items) {
    state.items = items;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
