<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import VueMeta from "vue-meta";
import { mapGetters } from "vuex";
import { startIntercomMessenger } from "@/utils/intercom";
import { updateIntercomData } from "@/utils/intercom";

Vue.use(VueMeta);

export default {
  name: "App",
  created() {
    startIntercomMessenger(this.user, this.lg);
  },
  metaInfo() {
    const description = this.$gettext(
      "Smart Applicant Tracking System (ATS) made for smarter you. Publish job offers, Distribute on specialised " +
        "jobboards and Hire for free with our all-in-one ATS solution. Discover all the tools you need for hassle-free " +
        "hiring with our free recruitment software."
    );
    return {
      htmlAttrs: {
        lang: this.lg
      },
      title: this.$route.meta.title || this.$gettext("Dashboard"),
      titleTemplate: this.$gettext(
        "%s | Enroll Talent - Free Applicant Tracking System"
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        }
      ]
    };
  },
  computed: {
    ...mapGetters("me", ["user", "lg"])
  },
  watch: {
    $route: () => {
      updateIntercomData();
    }
  }
};
</script>
