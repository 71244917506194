import axios from "axios";
import { jobboards } from "@/enums/jobboards";

const state = () => ({
  offer_count: 0,
  application_count: 0,
  company_count: 0,
  candidate_count: 0,
  user_count: 0,
  applications: [],
  publishers: []
});

const getters = {
  offerCount(state) {
    return state.offer_count;
  },
  applicationCount(state) {
    return state.application_count;
  },
  companyCount(state) {
    return state.company_count;
  },
  candidateCount(state) {
    return state.candidate_count;
  },
  userCount(state) {
    return state.user_count;
  },
  applications(state) {
    return state.applications;
  },
  publishers(state) {
    return state.publishers.map(publisher => {
      return {
        ...publisher,
        label: jobboards.from(publisher.source).label ?? publisher.source
      };
    });
  }
};

const actions = {
  fetch({ commit }) {
    return axios
      .get("/ats/dashboard")
      .then(({ data }) => commit("setData", data));
  }
};
const mutations = {
  setData(
    state,
    {
      offer_count,
      application_count,
      company_count,
      candidate_count,
      user_count,
      applications,
      publishers
    }
  ) {
    state.offer_count = offer_count;
    state.application_count = application_count;
    state.company_count = company_count;
    state.candidate_count = candidate_count;
    state.user_count = user_count;
    state.applications = applications;
    state.publishers = publishers;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
