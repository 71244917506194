export default {
  total(state) {
    return state.total || 0;
  },
  currentPage(state) {
    return state.currentPage;
  },
  perPage(state) {
    return state.perPage;
  },
  getEndpoint(state) {
    return state.endpoint;
  },
  getDataSourceName(state) {
    return state.dataSourceName;
  },
  getExtraEndpointArguments(state) {
    const args = state.extraEndpointArguments || [];
    return args.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
  },
  getMultiselectEndpoint(state) {
    return state.multiselectEndpoint;
  },
  items(state) {
    return state.items;
  },
  item(state) {
    return state.item;
  },
  multiselectItems(state) {
    return state.multiselectItems;
  },
  multiselectTotal(state) {
    return state.multiselectTotal;
  },
  options(state) {
    return state.options;
  },
  hasItems(state) {
    return state.items.length > 0;
  },
  firstItem(state) {
    return state.items.length > 0 ? state.items[0] : {};
  },
  schema(state) {
    return state.schema;
  },
  getSchemaColumn(state) {
    return name =>
      state.schema.find(column => {
        return column.name === name;
      });
  },
  filters(state) {
    return state.filters;
  },
  getFilterColumnInputValue(state) {
    return id => state.filters[id].column;
  },
  schemaEndpoint(state) {
    return state.schemaEndpoint;
  },
  hasOptions(state) {
    return state.options.length > 0;
  },
  hasSearch(state) {
    return state.search.length > 0;
  },
  search(state) {
    return state.search;
  },
  sort(state) {
    return state.sort;
  },
  metadata(state) {
    return state.metadata || {};
  },
  productUsage(state) {
    return state.metadata.product.usage;
  },
  productLimit(state) {
    return state.metadata.product.limit;
  },
  hasReachedProductLimit(state) {
    return state.metadata.product.usage >= state.metadata.product.limit;
  },
  showBlankState(state) {
    return (
      state.items.length === 0 &&
      state.search.length === 0 &&
      state.isLoaded === true &&
      state.filters.length === 0
    );
  },
  isLoaded(state) {
    return state.isLoaded;
  }
};
