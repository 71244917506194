import { createEnum } from "@/enums/baseEnum";
import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

export const jobboards = createEnum({
  /* jb-gen-placeholder */
  EmploisAssuranceCourtage: {
    value: "emplois_assurance_courtage",
    get label() {
      return $gettext("Emplois en Assurance / Courtage - RCCAQ");
    }
  },
  Jhubzcom: {
    value: "jhubzcom",
    get label() {
      return $gettext("jhubz.com");
    }
  },
  EmploisSante: {
    value: "emplois_sante",
    get label() {
      return $gettext("Emplois Santé");
    }
  },
  LeMarche: {
    value: "lemarche",
    get label() {
      return $gettext("LeMarche.co");
    }
  },
  ComptaFinance: {
    value: "comptafinance",
    get label() {
      return $gettext("ComptaFinance.com");
    }
  },
  TonMetier: {
    value: "tonmetier",
    get label() {
      return $gettext("TonMetier.com");
    }
  },
  EmploisRH: {
    value: "emploisrh",
    get label() {
      return $gettext("EmploisRH.ca");
    }
  },
  EmploisTI: {
    value: "emploisti",
    get label() {
      return $gettext("EmploisTI.com");
    }
  },
  LesJuristes: {
    value: "lesjuristes",
    get label() {
      return $gettext("LesJuristes.ca");
    }
  },
  LaVente: {
    value: "lavente",
    get label() {
      return $gettext("LaVente.ca");
    }
  },
  EmploisAdmin: {
    value: "emploisadmin",
    get label() {
      return $gettext("EmploisAdmin.com");
    }
  },
  EducEmplois: {
    value: "educemplois",
    get label() {
      return $gettext("EducEmplois.com");
    }
  },
  LinkedInJobs: {
    value: "linkedinJobs",
    get label() {
      return $gettext("LinkedIn");
    }
  },
  GoogleJobs: {
    value: "googleJobs",
    get label() {
      return $gettext("Google");
    }
  },
  JoobleJobs: {
    value: "joobleJobs",
    get label() {
      return $gettext("Jooble");
    }
  },
  NeuvooJobs: {
    value: "neuvooJobs",
    get label() {
      return $gettext("Talent.com");
    }
  },
  Career: {
    value: "career",
    get label() {
      return $gettext("Careers Page");
    }
  },
  MonsterJobs: {
    value: "monsterJobs",
    get label() {
      return $gettext("Monster");
    }
  },
  CareerJet: {
    value: "careerjet",
    get label() {
      return $gettext("Careerjet");
    }
  },
  JobBankCanada: {
    value: "jobBankCanadaJobs",
    get label() {
      return $gettext("Job Bank Canada");
    }
  },
  JobTome: {
    value: "jobtome",
    get label() {
      return $gettext("Jobtome");
    }
  },
  WhatJobs: {
    value: "whatJobs",
    get label() {
      return $gettext("WhatJobs");
    }
  },
  HotelsJobs: {
    value: "hotelsjobs",
    get label() {
      return $gettext("HotelsJobs.ca");
    }
  }
});
