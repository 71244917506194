import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    endpoint: "teams",
    searchables: ["name"]
  }),
  getters: {
    ...getters
  },
  actions: {
    ...actions,
    updateTeam({ getters, commit }, { id, ...payload }) {
      return axios.put(`ats/${getters.getEndpoint}/${id}`, payload).then(() => {
        const teamNamePayload = {
          id,
          ...payload
        };
        commit("setTeamName", teamNamePayload);
        commit("me/setTeamName", teamNamePayload, { root: true });
      });
    }
  },
  mutations: {
    ...mutations,
    setTeamName(state, { id, name }) {
      const item = state.items.find(item => item.id === id);
      Object.assign(item, { name });
    }
  }
};
