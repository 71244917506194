import { createEnum } from "@/enums/baseEnum";
import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

export const applicationDocumentType = createEnum({
  Resume: {
    value: "resume",
    get label() {
      return $gettext("Resume");
    }
  },
  CoverLetter: {
    value: "cover_letter",
    get label() {
      return $gettext("Cover Letter");
    }
  },
  Misc: {
    value: "misc",
    get label() {
      return $gettext("Document");
    }
  }
});
