import axios from "axios";

const state = () => ({
  products: []
});

const getters = {
  products(state) {
    return state.products;
  },
  productsByType(state) {
    return type => state.products.filter(product => product.type === type);
  }
};

const actions = {
  fetchAll({ commit }) {
    return axios.get("ats/products").then(({ data }) => {
      commit("setProducts", data);
      //commit("cart/setAllProducts", data, { root: true });
    });
  }
};

const mutations = {
  setProducts(state, products) {
    state.products = products;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
