import router from "./router";
import store from "./store";
import axios from "axios";

let isRefreshing = false;
let failedQueue = [];

const processQueue = error => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });
  failedQueue = [];
};

const fetchRefreshToken = () => {
  return axios.post("ats/auth/refresh");
};

export default error => {
  const originalRequest = error.config;

  if (
    error.response.status === 401 &&
    !originalRequest._retry &&
    originalRequest.url !== "ats/auth/refresh"
  ) {
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(() => {
          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function(resolve, reject) {
      fetchRefreshToken()
        .then(() => {
          processQueue(null);
          resolve(axios(originalRequest));
        })
        .catch(err => {
          processQueue(err);
          store.commit("me/setIsAuth", false);
          router.push("/login");
          reject(err);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }

  return Promise.reject(error);
};
