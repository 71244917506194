import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    endpoint: "users",
    searchables: ["first_name", "last_name", "email", "phone_number"]
  }),
  getters,
  actions,
  mutations
};
