import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    endpoint: "candidates",
    multiselectEndpoint: "options/candidates",
    searchables: [
      "candidates.full_name",
      "candidates.email",
      "candidates.phone_number"
    ],
    schemaEndpoint: "schemas/candidate",
    dataSourceName: "candidate"
  }),
  getters: {
    ...getters,
    applications(state) {
      return state.item.applications;
    },
    documents(state) {
      return state.item.applications.reduce((acc, application) => {
        const documents = application.documents.map(document => {
          return {
            ...document,
            offer: {
              id: application.offer.id,
              title: application.offer.title
            }
          };
        });
        return [...acc, ...documents];
      }, []);
    },
    multiselectItems(state) {
      return state.multiselectItems.map(option => {
        return {
          ...option,
          $isDisabled: option.applications_count
        };
      });
    }
  },
  actions: {
    ...actions,
    apply(context, payload) {
      return axios.post(`ats/applications`, payload);
    },
    updateApplicationStatus({ commit }, { id, status }) {
      return axios
        .put(`ats/applications/${id}`, { status })
        .then(() => commit("setApplicationStatus", { id, status }));
    }
  },
  mutations: {
    ...mutations,
    setApplicationStatus(state, { id, status }) {
      state.item.applications.find(
        application => application.application_id === id
      ).status = status;
    }
  }
};
