import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    endpoint: "saved-filters",
    searchables: ["name"]
  }),
  getters: {
    ...getters
  },
  actions,
  mutations
};
