import axios from "axios";
import store from "@/store";
export const parseOfferAttribute = attribute => {
  return {
    ...attribute,
    label: attribute.label[store.getters["me/lg"]]
  };
};

const state = () => ({
  attributes: {
    schedule: [],
    category: [],
    education: [],
    specialization: [],
    experience: [],
    certification: [],
    skill: []
  }
});

const getters = {
  categories(state) {
    return state.attributes.category.map(parseOfferAttribute);
  },
  specializations(state) {
    return state.attributes.specialization.map(parseOfferAttribute);
  },
  experiences(state) {
    return state.attributes.experience.map(parseOfferAttribute);
  },
  certifications(state) {
    return state.attributes.certification.map(parseOfferAttribute);
  },
  educations(state) {
    return state.attributes.education.map(parseOfferAttribute);
  },
  jobTypes(state) {
    return state.attributes.schedule.map(parseOfferAttribute);
  },
  skills(state) {
    return state.attributes.skill.map(parseOfferAttribute);
  }
};

const actions = {
  fetchAttributes({ commit }) {
    return axios.get("offer-attributes").then(({ data }) => {
      commit("setAttributes", data);
    });
  }
};

const mutations = {
  setAttributes(state, attributes) {
    state.attributes = attributes;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
