import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import mutations from "@/store/ajax/mutations";
import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    offerId: null,
    statusFilter: "all",
    searchables: ["candidates.email", "candidates.full_name"],
    schemaEndpoint: "schemas/offer-application",
    dataSourceName: "offer-application"
  }),
  getters: {
    ...getters,
    getEndpoint(state) {
      return `offers/${state.offerId}/applications`;
    }
  },
  actions: {
    ...actions,
    updateApplicationStatus({ commit }, { id, status }) {
      return axios
        .put(`ats/applications/${id}`, { status })
        .then(() => commit("setApplicationStatus", status));
    },
    createApplicationNote({ commit }, { applicationId, note }) {
      return axios
        .post(`ats/applications/${applicationId}/notes`, { note })
        .then(({ data }) => commit("addApplicationNote", data));
    },
    updateApplicationNote({ commit }, { applicationId, noteId, note }) {
      return axios
        .put(`ats/applications/${applicationId}/notes/${noteId}`, { note })
        .then(({ data }) => commit("updateApplicationNote", data));
    },
    deleteApplicationNote({ commit }, { applicationId, noteId }) {
      return axios
        .delete(`ats/applications/${applicationId}/notes/${noteId}`)
        .then(() => commit("deleteApplicationNote", noteId));
    }
  },
  mutations: {
    ...mutations,
    setOfferId(state, offerId) {
      state.offerId = offerId;
    },
    setStatusFilter(state, status) {
      state.extraEndpointArguments = [{ key: "status", value: status }];
    },
    setApplicationStatus(state, status) {
      if (state.item) {
        state.item.status = status;
      }
    },
    addApplicationNote(state, note) {
      state.item.notes.push(note);
    },
    updateApplicationNote(state, data) {
      state.item.notes = state.item.notes.map(note => {
        if (note.id === data.id) {
          return data;
        }
        return note;
      });
    },
    deleteApplicationNote(state, noteId) {
      state.item.notes = state.item.notes.filter(note => {
        return note.id !== noteId;
      });
    }
  }
};
