import axios from "axios";
import { translateObjectKeys } from "@/utils/translation";
import { productType } from "@/enums/products";

const state = () => ({
  subscription: {},
  products: []
});

const getters = {
  stripeInterval(state) {
    return state.subscription.stripe_interval;
  },
  hasPeriodEnd(state) {
    return (
      state.subscription.stripe_current_period_end !== null &&
      state.subscription.stripe_current_period_end !== undefined
    );
  },
  products(state, getters, rootState, rootGetters) {
    return state.products
      .map(translateObjectKeys(rootGetters["me/lg"], ["title"]))
      .filter(product => !["bump", "featured"].includes(product.name));
  },
  hasUpdatableSubscription(state) {
    return !["canceled", "incomplete_expired", "none", undefined].includes(
      state.subscription.subscription_status
    );
  },
  hasSubscription(state) {
    return state.subscription.stripe_subscription_id !== undefined;
  },
  isActive(state) {
    return state.subscription.subscription_status === "active";
  },
  status(state) {
    return state.subscription.subscription_status;
  },
  isCancelAtPeriodEnd(state) {
    return state.subscription.stripe_cancel_at_period_end;
  },
  currentPeriodEnd(state, getters, rootState, rootGetters) {
    return new Date(
      state.subscription.stripe_current_period_end
    ).toLocaleDateString(rootGetters["me/lg"], {
      month: "long",
      year: "numeric",
      day: "numeric"
    });
  },
  canPublishOnPartnerOrOurNetwork(state) {
    return state.products.some(
      product =>
        product.type === productType.our_network ||
        product.type === productType.partner_network
    );
  }
};

const actions = {
  fetch({ commit }) {
    return axios.get("/ats/subscription").then(({ data }) => {
      commit("setSubscription", data);
      commit("cart/setStripeInterval", data.parsed_stripe_interval, {
        root: true
      });
    });
  },
  updateProduct(context, payload) {
    return axios.put("/ats/subscription/products", payload);
  },
  deleteProduct(context, id) {
    return axios.delete(`/ats/subscription/products/${id}`);
  },
  reactivateProduct(context, id) {
    return axios.put(`/ats/subscription/products/${id}/reactivate`);
  },
  cancel(context, payload) {
    return axios.put(`/ats/subscription/cancel`, payload);
  },
  reactivate() {
    return axios.put(`/ats/subscription/reactivate`);
  }
};

const mutations = {
  setSubscription(state, team) {
    state.subscription = team;
    state.products = team.products;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
