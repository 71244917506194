//@todo rename to generic name and use as a default interceptor.
import Vue from "vue";

export const handleFormError = (dispatch, commit) => error => {
  switch (error.response.status) {
    case 422:
      dispatch("form/setErrors", error.response.data, { root: true });
      commit("form/setResponseStatus", error.response.status, { root: true });
      break;
    case 413:
      Vue.$toast.warning("The maximum allowed file size is 25M");
      break;
  }
  throw error;
};
