const state = () => ({
  history: [],
  isGoingBack: false
});

const getters = {
  hasRoutes(state) {
    return state.history.length > 0;
  },
  history(state) {
    return state.history;
  },
  isGoingBack(state) {
    return state.isGoingBack;
  }
};

const actions = {};

const mutations = {
  push(state, route) {
    state.history.push(route.path);
  },
  pop(state) {
    state.history.pop();
  },
  init(state) {
    state.history = [];
  },
  setIsGoingBack(state, value) {
    state.isGoingBack = value;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
