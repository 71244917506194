import axios from "axios";

const state = () => ({
  items: []
});

const getters = {
  items(state) {
    return state.items;
  },
  showBlankState(state) {
    return state.items.length === 0;
  }
};

const actions = {
  fetch({ commit }) {
    return axios
      .get("/ats/subscription/invoices")
      .then(({ data }) => commit("setItems", data));
  }
};

const mutations = {
  setItems(state, invoices) {
    state.items = invoices;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
