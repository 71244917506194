import axios from "axios";

const applyCoupon = (amount, { percent_off, amount_off, valid }) => {
  if (valid !== true) {
    return amount;
  }
  if (percent_off) {
    return amount - amount * (percent_off / 100);
  }
  if (amount_off) {
    return amount - amount_off / 100;
  }
  return amount;
};

const state = () => ({
  response: null,
  redirectPath: null,
  coupon: {},
  products: [],
  allProducts: {},
  taxes: [
    {
      name: "tps",
      percent: 0.05
    },
    {
      name: "tvq",
      percent: 0.09975
    }
  ],
  stripe_interval: "year"
});

const getters = {
  allProducts(state) {
    return state.allProducts;
  },
  productIntervalAmount: (state, getters) => productId => {
    return getters.allProducts[productId]["interval_amount"][
      getters.getStripeInterval
    ];
  },
  productDisplayPricing: (
    state,
    getters,
    rootState,
    rootGetters
  ) => productId => {
    return getters.allProducts[productId]["display_pricing"][
      getters.getStripeInterval
    ][rootGetters["me/lg"]];
  },
  response(state) {
    return state.response;
  },
  getStripeInterval(state) {
    return state.stripe_interval;
  },
  redirectPath(state) {
    return state.redirectPath;
  },
  products(state) {
    return state.products;
  },
  hasProduct(state) {
    return productId => state.products.some(({ id }) => id === productId);
  },
  hasCoupon(state) {
    return (
      state.coupon.valid === true &&
      (state.coupon.percent_off || state.coupon.amount_off)
    );
  },
  isEmpty(state) {
    return state.products.length === 0;
  },
  productsCount(state) {
    return state.products.length;
  },
  coupon(state) {
    return state.coupon || {};
  },
  /**
   * Before coupon
   */
  rawSubTotal(state, getters) {
    return state.products.reduce((sum, product) => {
      return sum + product.quantity * getters.productIntervalAmount(product.id);
    }, 0);
  },
  monthlySubTotal(state, getters) {
    return getters.getStripeInterval === "year"
      ? (getters.subTotal / 12).toFixed(2)
      : getters.subTotal;
  },
  subTotal(state, getters) {
    return applyCoupon(getters.rawSubTotal, getters.coupon);
  },
  discount(state, getters) {
    return getters.rawSubTotal - getters.subTotal;
  },
  tvqAmount(state, getters) {
    const percent = state.taxes.find(tax => tax.name === "tvq").percent;
    return (getters.subTotal * percent).toFixed(2);
  },
  tpsAmount(state, getters) {
    const percent = state.taxes.find(tax => tax.name === "tps").percent;
    return (getters.subTotal * percent).toFixed(2);
  },
  total(state, getters) {
    return (
      getters.subTotal +
      parseFloat(getters.tvqAmount) +
      parseFloat(getters.tpsAmount)
    ).toFixed(2);
  }
};

const actions = {
  init({ commit }) {
    return axios.get("ats/products").then(({ data }) => {
      commit("init", data);
    });
  },
  getCoupon({ commit }, coupon) {
    return axios
      .get(`ats/stripe/coupons/${coupon}`)
      .then(({ data }) => commit("setCoupon", data));
  },
  pay({ getters, commit }, { credit_card, coupon, stripe_interval }) {
    return axios
      .post("/ats/subscription/products/buy", {
        products: getters.products,
        credit_card_id: credit_card.id,
        coupon,
        stripe_interval
      })
      .then(response => {
        commit("emptyCart");
        commit("setResponse");
        return response;
      });
  }
};

const mutations = {
  /**
   * Make sure there are only valid products in the cart. And create a hashmap of valid product
   * @param state
   * @param products
   */
  init(state, products) {
    state.allProducts = products.reduce(
      (map, product) => ({ ...map, [product.id]: product }),
      {}
    );
    state.products = state.products
      .filter(({ id }) => state.allProducts[id] !== undefined)
      .map(({ id, quantity }) => ({ ...state.allProducts[id], quantity }))
      .filter(({ in_subscription }) => !in_subscription);
  },
  setResponse(state, response) {
    state.response = response;
  },
  setCoupon(state, coupon) {
    state.coupon = coupon;
  },
  clearCoupon(state) {
    state.coupon = {};
  },
  addProduct(state, product) {
    if (!state.products.some(({ id }) => id === product.id)) {
      state.products.push({
        ...product,
        quantity: 1
      });
    }
  },
  upQuantity(state, id) {
    state.products.map(product => {
      if (product.id === id) {
        product.quantity = Math.min(
          product.quantity + 1,
          product.limit_max - product.limit
        );
      }
      return product;
    });
  },
  downQuantity(state, id) {
    state.products.map(product => {
      if (product.id === id) {
        product.quantity = Math.max(product.quantity - 1, 1);
      }
      return product;
    });
  },
  removeProduct(state, id) {
    state.products = state.products.filter(product => product.id !== id);
  },
  emptyCart(state) {
    state.products = [];
    state.coupon = {};
  },
  setRedirectPath(state, path) {
    state.redirectPath = path;
  },
  setStripeInterval(state, interval) {
    if (interval !== null) {
      state.stripe_interval = ["month", "year"].includes(interval)
        ? interval
        : "year";
    }
  }
};

export default { namespaced: true, state, getters, actions, mutations };
