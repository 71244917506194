export const gtmScript = (w, d, s, l, i) => {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
};

export const trackView = (screenName, path, additionalEventData) => {
  if (typeof window !== "undefined") {
    const dataLayer = (window.dataLayer = window.dataLayer ?? []);
    dataLayer.push({
      ...additionalEventData,
      event: "content-view",
      "content-name": path,
      "content-view-name": screenName
    });
  }
};

export const gtmLoader = () => {
  process.env.VUE_APP_GOOGLE_GTM_ID.split(",").forEach(gtmId => {
    if (gtmId.length) {
      gtmScript(window, document, "script", "dataLayer", gtmId);
    }
  });
};
