import axios from "axios";

const state = () => ({
  users: [],
  user: {}
});

const getters = {
  hasUsers(state) {
    return state.users.length > 0;
  },
  users(state) {
    return state.users;
  },
  user(state) {
    return state.user;
  }
};

const actions = {
  fetchUsers({ commit }) {
    return axios.get("ats/users").then(({ data }) => commit("setUsers", data));
  },
  fetchUser({ commit }, id) {
    return axios
      .get(`ats/users/${id}`)
      .then(({ data }) => commit("setUser", data));
  },
  inviteUser(context, payload) {
    return axios.post("ats/users", payload);
  },
  updateUser(context, { id, ...payload }) {
    return axios.put(`ats/users/${id}`, payload);
  },
  deleteUser({ commit }, id) {
    return axios.delete(`ats/users/${id}`).then(() => commit("deleteUser", id));
  }
};

const mutations = {
  deleteUser(state, id) {
    state.users = state.users.filter(user => user.id !== id);
  },
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
