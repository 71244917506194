import axios from "axios";
import { permissions } from "@/enums/permissions";

const state = () => ({
  user: {
    teams: [],
    email_applications_filters: []
  },
  isAuth: false,
  lg: "fr",
  locales: ["en", "fr"],
  fallbackLg: "en"
});

const getters = {
  user(state) {
    return state.user;
  },
  emailApplicationsFilters(state) {
    return state.user.email_applications_filters || [];
  },
  isOrphan(state) {
    return state.isAuth && state.user.teams.length < 1;
  },
  isEmailVerified(state) {
    return state.user.email_verified === true;
  },
  permissions(state) {
    if (state.isAuth && state.user.permissions) {
      return state.user.permissions.map(permission => permission.name);
    }
    return [];
  },
  canManageUsers(state, getters) {
    return getters.permissions.includes(permissions.ManageUsers);
  },
  canManageCompanies(state, getters) {
    return getters.permissions.includes(permissions.ManageCompanies);
  },
  canManageOffers(state, getters) {
    return getters.permissions.includes(permissions.ManageOffers);
  },
  canManageCandidates(state, getters) {
    return getters.permissions.includes(permissions.ManageCandidates);
  },
  canManageSubscriptions(state, getters) {
    return getters.permissions.includes(permissions.ManageSubscriptions);
  },
  canManageCreditCard(state, getters) {
    return getters.permissions.includes(permissions.ManageCreditCards);
  },
  userId(state) {
    return state.user.id;
  },
  isAuth(state) {
    return state.isAuth;
  },
  lg(state) {
    return state.lg || state.fallbackLg;
  },
  targetLg(state) {
    return state.locales.filter(locale => locale !== state.lg)[0];
  }
};

const actions = {
  toggleLg({ commit }) {
    commit("toggleLg");
  },
  setLg({ commit }, lang) {
    commit("setLg", lang);
  },
  changePassword(context, payload) {
    return axios.put("ats/auth/change-password", payload);
  },
  updateProfile({ commit }, payload) {
    return axios
      .put("ats/profile", payload)
      .then(({ data }) => commit("setUser", data));
  },
  updateEmailApplicationFilter({ commit }, payload) {
    return axios
      .put("ats/profile/application-notification-setting", payload)
      .then(({ data }) => commit("setUser", data));
  },
  inviteUser(context, id) {
    return axios.post(`ats/users/${id}/invite`);
  },
  deleteUser({ commit }, id) {
    return axios.delete(`ats/users/${id}`).then(() => commit("deleteUser", id));
  },
  login({ commit }, payload) {
    return axios.post("ats/auth/login", payload).then(({ data }) => {
      commit("setUser", data);
      commit("setIsAuth", true);
    });
  },
  loginSso({ commit }, payload) {
    return axios
      .get(`ats/auth/sso/${payload.selector}/${payload.token}`)
      .then(({ data }) => {
        if (Object.keys(data).length !== 0) {
          commit("setUser", data);
          commit("setIsAuth", true);
        }
      });
  },
  signInAsUser({ commit }, payload) {
    return axios
      .get(`ats/auth/sign-in-as-user/${payload.selector}/${payload.token}`)
      .then(({ data }) => {
        if (Object.keys(data).length !== 0) {
          commit("setUser", data);
          commit("setIsAuth", true);
        }
      });
  },
  resendEmailConfirmationEmail() {
    return axios.post("ats/email-confirmation/resend");
  },
  fetchMe({ commit }) {
    return axios.get("ats/me").then(({ data }) => {
      if (Object.keys(data).length !== 0) {
        commit("setUser", data);
      }
    });
  },
  forgotPassword(context, payload) {
    return axios.post("ats/auth/forgot-password", payload);
  },
  signUp({ commit }, payload) {
    return axios.post("ats/signup/", payload).then(({ data }) => {
      commit("setUser", data);
      commit("setIsAuth", true);
    });
  },
  checkSecureLink(context, { type, selector, token }) {
    return axios.get(`check-secure-link/${type}/${selector}/${token}`);
  },
  confirmEmail({ commit }, payload) {
    return axios
      .post(`email-confirmation`, payload)
      .then(() => commit("setIsEmailVerified"));
  },
  resetPassword(context, payload) {
    return axios.put("auth/reset-password", payload);
  },

  acceptInvitation({ commit }, payload) {
    return axios.put("accept-invitation", payload).then(({ data }) => {
      commit("setUser", data);
      commit("setIsAuth", true);
    });
  },
  logout({ commit }) {
    return axios.post("ats/auth/logout").then(() => {
      commit("setUser", {});
      commit("setIsAuth", false);
      commit("creditCards/select", {}, { root: true });
    });
  },
  switchTeam({ commit }, teamId) {
    return axios
      .put(`ats/users/switch-team`, {
        team_id: teamId
      })
      .then(({ data }) => {
        commit("setUser", data);
      });
  }
};

const mutations = {
  toggleLg(state) {
    const lg = state.locales.filter(locale => locale !== state.lg)[0];
    document.cookie = `lg=${lg};path=/`;
    state.lg = lg;
  },
  setLg(state, lang) {
    if (state.locales.includes(lang)) {
      document.cookie = `lg=${lang};path=/`;
      state.lg = lang;
    }
  },
  setAvatar(state, avatar) {
    state.user.avatar = avatar;
  },
  setBackgroundImage(state, image) {
    state.user.background_image = image;
  },
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  deleteUser(state, id) {
    state.users = state.users.filter(user => user.id !== id);
  },
  setIsAuth(state, isAuth) {
    state.isAuth = isAuth;
  },
  setIsEmailVerified(state) {
    state.user.email_verified = true;
  },
  setTeamName(state, { id, name }) {
    const item = state.user.teams.find(item => item.id === id);
    Object.assign(item, { name });
  }
};

export default { namespaced: true, state, getters, actions, mutations };
