import axios from "axios";

const state = () => ({
  creditCards: [],
  creditCard: {},
  selected: {}
});

const getters = {
  creditCards(state) {
    return state.creditCards;
  },
  hasCreditCards(state) {
    return state.creditCards.length > 0;
  },
  selected(state) {
    return state.selected;
  }
};

const actions = {
  fetchAll({ commit }) {
    return axios
      .get("ats/credit-cards")
      .then(({ data }) => commit("setCreditCards", data));
  },
  create({ commit }, payload) {
    return axios.post("ats/credit-cards", payload).then(({ data }) => {
      commit("addCreditCard", data);
      commit("select", data);
    });
  },
  delete(context, id) {
    return axios.delete(`ats/credit-cards/${id}`);
  },
  setDefaultCard(context, id) {
    return axios.put(`ats/stripe/set-default-source/${id}`);
  }
};
const mutations = {
  setCreditCards(state, creditCards) {
    state.creditCards = creditCards;
  },
  addCreditCard(state, creditCard) {
    state.creditCards.push(creditCard);
  },
  select(state, creditCard) {
    state.selected = creditCard;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
