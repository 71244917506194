import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueToast from "vue-toast-notification";
import GetTextPlugin from "vue-gettext";
import translations from "@/translations.json";

import { applicationDocumentType } from "@/enums/applicationDocumentType";
Vue.prototype.$ApplicationDocumentType = applicationDocumentType;

import "./assets/css/bootstrap.min.css";
import "./assets/scss/themes.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import refreshTokenInterceptor from "@/refreshTokenInterceptor";

import * as VueGoogleMaps from "vue2-google-maps";

import { StripePlugin } from "@vue-stripe/vue-stripe";

// https://docs.vuestripe.com/vue-stripe/vue-stripe-plugin
const options = {
  pk: process.env.VUE_APP_STRIPE_PK,
  apiVersion: "2019-08-14"
};

Vue.use(StripePlugin, options);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD4OenaR2UK-0zWfPNaZ8923tSN1EgE-3I",
    libraries: "places",
    loading: "async"
  }
});

import {
  BButtonGroup,
  ModalPlugin,
  ButtonPlugin,
  FormSelectPlugin,
  FormInputPlugin,
  FormGroupPlugin,
  FormTextareaPlugin,
  FormDatepickerPlugin,
  InputGroupPlugin,
  FormFilePlugin,
  FormRadioPlugin,
  FormCheckboxPlugin,
  BTable
} from "bootstrap-vue";

Vue.use(ModalPlugin);
Vue.use(ButtonPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(InputGroupPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormCheckboxPlugin);
Vue.component("b-table", BTable);
Vue.component("b-button-group", BButtonGroup);
import { PaginationPlugin } from "bootstrap-vue";
Vue.use(PaginationPlugin);

import { NavPlugin } from "bootstrap-vue";
Vue.use(NavPlugin);

import { DropdownPlugin } from "bootstrap-vue";
import { mapActions } from "vuex";
Vue.use(DropdownPlugin);

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en: "English",
    fr: "Français"
  },
  defaultLanguage: "en",
  translations: translations
});
Vue.config.language = store.getters["me/lg"];
Vue.config.productionTip = false;

axios.defaults.baseURL = "/api/";

Vue.use(VueAxios, axios);
Vue.use(VueToast, {
  position: "top-right",
  duration: 10000
});

document.cookie = `lg=${store.getters["me/lg"]};path=/`;
axios.interceptors.response.use(response => response, refreshTokenInterceptor);
axios.interceptors.response.use(
  response => response,
  error => {
    switch (error.response.status) {
      case 403:
        Vue.$toast.warning(error.response.data.message);
        break;
      case 400:
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach(message => {
            Vue.$toast.warning(message);
          });
        } else {
          Vue.$toast.warning(error.response.data.message);
        }
        break;
      case 500:
        Vue.$toast.error("Whoops something went wrong.");
        break;
      case 422:
        break; // This is handled in formErrorHandler @todo consolidate
      default:
        Vue.$toast.warning(error.response.data.message);
    }
    throw error;
  }
);

store.commit("history/init");
router.beforeEach(async (to, from, next) => {
  if (from.path !== "/" && store.getters["history/isGoingBack"] === false) {
    await store.commit("history/push", to);
  }
  await store.commit("history/setIsGoingBack", false);
  next();
});

router.beforeEach(({ meta: { guard, permission }, name }, from, next) => {
  if (guard && !store.getters["me/isAuth"]) {
    return next("/login");
  }

  if (guard && store.getters["me/isOrphan"] && name !== "create-team") {
    return next({ name: "create-team" });
  }
  if (permission && !store.getters["me/permissions"].includes(permission)) {
    return next("/");
  }
  return next();
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("form/init");
  next();
});

router.beforeEach(async ({ name }, from, next) => {
  document.body.className = "";
  switch (name) {
    case "login":
    case "forgot-password":
    case "forgot-password-success":
    case "reset-password":
    case "invalid-link":
    case "accept-invitation":
    case "email-confirmation":
      document.body.classList.add("login-page");
      break;
    case "signup":
      document.body.classList.add("register-page");
      break;
  }
  next();
});

router.beforeEach((to, from, next) => {
  to.meta.fetching !== false
    ? store.commit("app/startFetching")
    : store.commit("app/stopFetching");
  next();
});

router.afterEach(to => {
  trackView(to.name, to.fullPath);
});

import { gtmLoader, trackView } from "@/utils/gtm";
gtmLoader();

new Vue({
  router,
  store,
  methods: {
    ...mapActions("me", ["fetchMe"])
  },
  beforeMount() {
    this.fetchMe();
  },
  render: h => h(App)
}).$mount("#app");
