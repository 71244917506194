import { handleFormError } from "@/store/handlers";

const state = () => ({
  errors: {},
  isPosting: false,
  response: {
    status: null
  }
});

const getters = {
  isPosting(state) {
    return state.isPosting;
  },
  errors(state) {
    return state.errors;
  },
  fieldHasError(state) {
    return field => state.errors[field] !== undefined;
  },
  getFieldError(state) {
    return field => (state.errors[field] ? state.errors[field][0] : "");
  },
  hasError: state => field => {
    return Object.prototype.hasOwnProperty.call(state.errors, field);
  },
  hasValidationErrors(state) {
    return state.response.status === 422;
  }
};

const actions = {
  submit({ dispatch, commit }, action) {
    commit("clearErrors");
    commit("setResponseStatus", null);
    commit("setIsPosting", true);
    return action()
      .catch(handleFormError(dispatch, commit))
      .finally(() => commit("setIsPosting", false));
  },
  setIsPosting({ commit }, bool) {
    // @todo remove use mutation instead
    commit("setIsPosting", bool);
  },
  setErrors({ commit }, errors) {
    // @todo remove use mutation instead
    commit("setErrors", errors);
  },
  clearErrors({ commit }) {
    // @todo remove use mutation instead
    commit("clearErrors");
  },
  init({ commit }) {
    commit("init");
  }
};

const mutations = {
  setIsPosting(state, isPosting) {
    state.isPosting = isPosting;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  clearErrors(state) {
    state.errors = {};
  },
  init(state) {
    state.errors = {};
    state.isPosting = false;
  },
  setResponseStatus(state, status) {
    state.response.status = status;
  }
};

export default { namespaced: true, state, getters, actions, mutations };
