import { requestFilterToFilters } from "@/utils/filters";

const toggleOrder = order => (order === "asc" ? "desc" : "asc");

export default {
  setTotal(state, value) {
    state.total = value;
  },
  setPerPage(state, value) {
    state.perPage = value;
  },
  setCurrentPage(state, value) {
    state.currentPage = value;
  },
  delete(state, id) {
    state.items = state.items.filter(item => item.id !== id);
  },
  setMetadata(state, metadata) {
    state.metadata = metadata;
  },
  setItems(state, items) {
    state.items = items;
    state.isLoaded = true;
  },
  setItem(state, item) {
    state.isLoaded = true;
    state.item = item;
  },
  setMultiselectItems(state, items) {
    state.multiselectItems = items;
  },
  setMultiselectTotal(state, total) {
    state.multiselectTotal = total;
  },
  setSort(state, field) {
    const order =
      state.sort.field === field ? toggleOrder(state.sort.order) : "asc";
    state.sort = { field, order };
  },
  setBTableSort(state, { sortBy, sortDesc }) {
    state.sort = {
      field: state.sortKeyMapping[sortBy] || sortBy,
      order: sortDesc ? "desc" : "asc"
    };
  },
  setSearch(state, search) {
    state.search = search;
  },
  setIsLoaded(state, isLoaded) {
    state.isLoaded = isLoaded;
  },
  setOptions(state, options) {
    state.options = options;
  },
  setSchema(state, schema) {
    state.schema = schema;
  },
  addFilter(state, filter) {
    state.filters.push(filter);
  },
  deleteFilter(state, id) {
    const index = state.filters.findIndex(filter => filter.id === id);
    state.filters.splice(index, 1);
  },
  loadFilters(state, savedFilter) {
    if (savedFilter.filters && savedFilter.filters.length > 0) {
      state.filters = requestFilterToFilters(savedFilter.filters, state.schema);
    }
  },
  clearFilters(state) {
    state.filters = [];
  },
  setExtraEndpointArguments(state, args) {
    state.extraEndpointArguments = args;
  },
  reset(state) {
    state.items = [];
    state.item = {};
    state.options = [];
    state.search = "";
    state.sort = { field: "created_at", order: "desc" };
    state.isLoaded = false;
    state.filters = [];
    state.extraEndpointArguments = [];
  }
};
