import state from "@/store/ajax/state";
import getters from "@/store/ajax/getters";
import actions from "@/store/ajax/actions";
import axios from "axios";
import mutations from "@/store/ajax/mutations";
import { parseOfferAttribute } from "@/store/modules/offerAttribute";

const parseCompany = company => {
  return {
    ...company,
    name_fr: company.is_deleted
      ? company.name_fr.concat(" [DELETED]")
      : company.name_fr
  };
};

const parseOffer = offer => {
  const offer_attributes = Object.keys(offer.offer_attributes || {}).reduce(
    (acc, key) => {
      acc[key] = offer.offer_attributes[key].map(parseOfferAttribute);
      return acc;
    },
    {}
  );

  return {
    ...offer,
    company: parseCompany(offer.company),
    offer_attributes
  };
};

export default {
  namespaced: true,
  state: () => ({
    ...state(),
    stats: {
      totalByStatus: {}
    },
    options: [],
    endpoint: "offers",
    multiselectEndpoint: "options/offers",
    dataSourceName: "offer",
    schemaEndpoint: "schemas/offer",
    searchables: [
      "title",
      "companies.name_fr",
      "internal_reference_number",
      "offers.location->city",
      "users.full_name"
    ],
    sortKeyMapping: { "company.name_fr": "companies.name_fr" },
    item: {
      company: {},
      applications: [],
      location: {},
      offer_attributes: []
    },
    applicationId: null
  }),
  getters: {
    ...getters,
    items(state) {
      return state.items.map(parseOffer);
    },
    item(state) {
      return parseOffer(state.item);
    },
    options(state) {
      return state.options;
    },
    offerTitle(state) {
      if (state.item.contents) {
        return state.item.contents[state.item.primary_language].title;
      }
      return "";
    },
    applications(state) {
      return state.item.applications;
    },
    application(state) {
      return (
        (state.application = state.item.applications.find(
          application =>
            parseInt(application.id) === parseInt(state.applicationId)
        )) || {
          candidate: {},
          notes: [],
          documents: []
        }
      );
    },
    offerSwitchDropdownSelectedItem(state) {
      return state.item.id
        ? {
            id: state.item.id,
            title: state.item.contents[state.item.primary_language].title,
            logo: state.item.company.logo
          }
        : null;
    },
    newApplicationCount(state) {
      return state.stats.totalByStatus?.new || 0;
    },
    stats(state) {
      return state.stats;
    }
  },
  actions: {
    ...actions,
    fetchOptions({ commit }) {
      return axios
        .get("ats/options/offers")
        .then(({ data }) => commit("setOptions", data.data));
    },
    fetchStats({ commit }, offerId) {
      return axios
        .get(`/ats/offers/${offerId}/stats`)
        .then(({ data }) => commit("setOfferStats", data));
    }
  },
  mutations: {
    ...mutations,
    setOptions(state, offers) {
      state.options = offers;
    },
    setApplication(state, id) {
      state.application = state.item.applications.find(
        application => application.id === id
      );
    },
    setApplicationId(state, id) {
      state.applicationId = id;
    },
    setApplicationStatus(state, { status }) {
      state.application.status = status;
    },
    setOfferStats(state, stats) {
      state.stats = stats;
    }
  }
};
