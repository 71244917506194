export const baseEnum = {
  from(value) {
    return Object.values(this).find(prop => prop?.value === value) ?? {};
  },
  toOptions() {
    return Object.values(this).reduce((acc, currentValue) => {
      if (
        Object.hasOwn(currentValue, "value") &&
        Object.hasOwn(currentValue, "label")
      ) {
        acc.push(currentValue);
      }
      return acc;
    }, []);
  }
};

export const createEnum = enumObj => {
  return Object.freeze({
    ...baseEnum,
    ...enumObj
  });
};
