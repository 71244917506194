import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import app from "./modules/app";
import me from "./modules/me";
import history from "./modules/history";
import form from "./modules/form";
import jobboards from "./modules/jobboards";
import user from "./modules/user";
import companies from "./modules/companies";
import offers from "./modules/offers";
import offerApplications from "./modules/offerApplications";
import candidates from "./modules/candidates";
import teams from "./modules/teams";
import teamUsers from "./modules/teamUsers";
import dashboard from "./modules/dashboard";
import recentActivity from "./modules/recentActivity";
import offerAttribute from "@/store/modules/offerAttribute";
import products from "@/store/modules/products";
import cart from "@/store/modules/cart";
import creditCards from "@/store/modules/creditCards";
import subscriptions from "@/store/modules/subscription";
import applications from "@/store/modules/applications";
import nocJobTitles from "@/store/modules/nocJobTitles";
import search from "@/store/modules/search";
import invoices from "@/store/modules/invoices";
import savedFilters from "@/store/modules/savedFilters";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    me,
    history,
    form,
    jobboards,
    user,
    companies,
    offers,
    offerApplications,
    candidates,
    teams,
    teamUsers,
    dashboard,
    recentActivity,
    offerAttribute,
    products,
    cart,
    creditCards,
    subscriptions,
    applications,
    nocJobTitles,
    search,
    invoices,
    savedFilters
  },
  plugins: [
    new VuexPersistence({
      reducer: state => ({ me: state.me, cart: state.cart })
    }).plugin
  ]
});
